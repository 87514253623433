<template>
  <div>
    <content-template
      :menuName="`${
        inputType == 'Edit' ? $t('general.edit') : $t('general.new')
      } ${$t('sidebar.educationalContent')}`"
      :backButton="true"
      @backToPage="moveToOther('Edc Content')"
    >
      <template #form>
        <form>
          <Input
            v-model="edited_item.title"
            :label="$t('general.title')"
            class="s-mb-20"
            :error="validation.title"
          />
          <Input
            :label="$t('general.date')"
            type="date"
            v-model="edited_item.date"
            class="s-mb-20"
            :error="validation.date"
          />
          <Input
            :label="$t('general.time')"
            type="time"
            v-model="edited_item.time"
            class="s-mb-20"
            :error="validation.time"
          />
          <div class="s-mb-20">
            <label class="s-input-label">{{ $t("general.topics") }}</label>

            <Multiselect
              v-model="edited_item.expertise_detail"
              placeholder="Search or add a topic"
              label="name"
              :options="expertise_dropdown.map((type) => type.id)"
              :custom-label="
                (opt) => expertise_dropdown.find((x) => x.id == opt).name
              "
              :multiple="true"
            ></Multiselect>
            <span class="s-input-error" v-if="validation.expertise_detail">
              {{ $t("general.fillThisField") }}
            </span>
          </div>
          <Input
            v-model="edited_item.youtube_link"
            :label="$t('general.youtubeLink')"
            class="s-mb-20"
          />
          <p class="s-text-primary s-weight-600">
            {{ $t("general.uploadImage") }}
          </p>
          <div v-if="inputType == 'Edit'">
            <img
              :src="edccontent_item.image"
              alt=""
              style="width: 100px; height: 100px; object-fit: contain"
            />
          </div>
          <div class="s-pb-30 s-position-relative">
            <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
              <div>
                <img
                  :src="require('@/assets/icons/primary/cloud_upload.png')"
                />
                <p>{{ $t("general.dragAndDrop") }}</p>
                <p>{{ $t("general.or") }}</p>
                <Button>{{ $t("general.uploadFile") }}</Button>
              </div>
            </div>
            <input
              v-if="!fileDoc.name"
              id="document"
              ref="document"
              class="s-hide"
              type="file"
              @change="importData('document')"
              multiple
              accept="image/png, image/jpg, image/jpeg"
            />
            <div class="s-position-relative s-fullWidth s-center-flex">
              <div
                class="
                  s-center-flex
                  s-mb-16
                  s-bg-green-40
                  s-plr-10
                  s-radius-10
                  s-fullWidth
                "
                v-if="fileDoc.name"
              >
                <p class="s-text-primary">{{ fileDoc.name }}</p>
                <img
                  :src="require('@/assets/icons/close-primary.svg')"
                  class="s-ml-auto s-cursor-pointer"
                  @click="fileDoc = {}"
                />
              </div>
            </div>
          </div>

          <Textarea
            v-model="edited_item.description"
            :label="$t('general.content')"
            class="s-mb-20"
            :error="validation.description"
          />
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click="cancelForm()">
              {{ $t("general.cancel") }}
            </Button>
            <Button
              class="s-ml-20"
              @click="onFinish()"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import common from "@/mixins/common";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  props: ["inputType", "item"],
  mixins: [form, common],
  data() {
    return {
      fileDoc: {},
      edited_item: {
        title: "",
        date: "",
        time: "",
        description: "",
        expertise_detail: [],
        youtube_link: "",
      },
      validation: {
        title: false,
        date: false,
        time: false,
        description: false,
        image: false,
        expertise_detail: false,
      },
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      expertise_dropdown: "expertise/getExpertise",
      edccontent_item: "edccontent/getEdcContent",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
  },
  mounted() {
    this.initData();
    this.getEditedData();
  },
  methods: {
    ...mapActions({
      edcContent: "edccontent/postEdcContentData",
      setExpertise: "expertise/setExpertiseDropdownData",
      setEdcContentSingleData: "edccontent/setEdcContentSingleData",
      edccontent_update: "edccontent/updateEdcContentData",
    }),

    async initData() {
      this.setLoadingPage(true);
      await this.setExpertise();
      this.setLoadingPage(false);
    },

    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        true,
        this.fileDoc.name,
        this.inputType
      );
      if (!this.empty_field) {
        this.isLoading = true;
        //Ambil id dari link youtube
        var youtube_id = "";
        if (
          this.edited_item.youtube_link != "" &&
          this.edited_item.youtube_link != null
        ) {
          youtube_id = this.generateVideoId(this.edited_item.youtube_link);
        }

        let formData = new FormData();
        formData.append("title", this.edited_item.title);
        formData.append("date", this.edited_item.date);
        formData.append("time", this.edited_item.time);
        formData.append("description", this.edited_item.description);
        formData.append("image", this.fileDoc);
        if (youtube_id != null) {
          formData.append("youtube_link", youtube_id);
        }

        for (var i = 0; i < this.edited_item.expertise_detail.length; i++) {
          formData.append(
            "expertise_detail_id[]",
            this.edited_item.expertise_detail[i]
          );
        }
        if (this.inputType == "New") {
          await this.edcContent(formData);
        } else {
          formData.append("id", this.edccontent_item.id);
          await this.edccontent_update(formData);
        }
        if (this.$store.getters["edccontent/getStatus"] == 1) {
          this.$router.push(this.$translate({ name: "Edc Content" }));
        } else {
          this.showSnackbar({
            type: "error",
            text: this.$t("general.addOrEdit"),
          });
        }
      }
    },

    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },

    async getEditedData() {
      if (this.inputType == "Edit") {
        await this.setEdcContentSingleData(this.$route.params.slug);

        this.edited_item = Object.assign({}, this.edccontent_item);
        let expertise_temp = new Array();
        this.edccontent_item.expertise_detail.forEach((element) => {
          expertise_temp.push(element.id);
        });
        this.edited_item.expertise_detail = Object.assign([], expertise_temp);
        if (this.edited_item.youtube_link != null) {
          this.edited_item.youtube_link = `https://www.youtube.com/embed/${this.edited_item.youtube_link}`;
        }
      }
    },

    cancelForm() {
      this.$router.push(
        this.$translate({
          name: "Edc Content",
        })
      );
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
